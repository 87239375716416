p-inputswitch.p-element {
  height: 24px;
}
.p-inputswitch {
  @apply w-[42px] h-[24px];

  .p-inputswitch-slider {
    border: 0.5px solid;
    box-shadow: 0 2px 4px 0 rgba(111, 148, 163, 0.15) inset, 0px -2px 4px 0px rgba(202, 242, 252, 0.25) inset;
    @apply bg-neutral-100 border-neutral-300 rounded-[999px];

    &:before {
      @apply bg-neutral-700 size-[18px] top-[36%];
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      @apply bg-primary-600;
    }

    .p-inputswitch-slider:before {
      @apply bg-neutral-0 translate-x-[16.5px];
    }
  }

  &.p-focus .p-inputswitch-slider {
    outline: none;
  }
}
