.p-overlaypanel:after, .p-overlaypanel:before {
  content: none;
}

.p-overlaypanel {
  width: 100px;
  margin-top: 4px;
  border: 1px solid #D3E8ED;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.10);
  cursor: pointer;
}

.p-overlaypanel-content {
  padding: 0;
}
