.avatar {
  @apply flex justify-center items-center w-12 h-12 rounded-full select-none border-[1.8px] border-neutral-0
  text-[10px] font-medium hover:ring-[2px]  hover:ring-neutral-300 active:ring-neutral-400 transition-all cursor-pointer;
}

.avatar-static {
  @apply flex justify-center items-center w-12 h-12 rounded-full text-[10px] font-medium cursor-default select-none
  border-[1.8px] border-neutral-0;
}

.avatar-check {
  @apply bg-green-700 rounded-full flex items-center w-4 h-4 justify-center ring-1 ring-neutral-0 absolute -right-0.5 -top-0.5 z-20;
}
.avatar-warning{
  @apply rounded-full flex items-center justify-center absolute -right-0.5 -top-0.5 z-20;
  height: 14px;
  width: 14px;
}
