.checkbox-wrapper *,
.checkbox-wrapper *:after,
.checkbox-wrapper *:before {
  box-sizing: border-box;
}


.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
}

.checkbox-wrapper input:checked + label svg path {
  stroke-dashoffset: 0;
}

.checkbox-wrapper input:checked + label {
  background-color: #34B8D9;
  border: 2px solid #34B8D9;
}

.checkbox-wrapper input:focus + label {
  transform: scale(1.03);
}

.checkbox-wrapper input + label {
  display: block;
  background-color: #E4F2F7;
  border: 2px solid #E4F2F7;
  width: var(--size);
  height: var(--size);
  border-radius: 6px;
  cursor: pointer;
  transition: all .2s ease;
}

.checkbox-wrapper input + label svg {
  pointer-events: none;
  padding: 5%;
}

.checkbox-wrapper input + label svg path {
  fill: none;
  stroke: #E4F2F7;
  stroke-width: 4px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 101;
  transition: all 250ms cubic-bezier(1, 0, .37, .91);
}

.crm-checkbox *,
.crm-checkbox *:after,
.crm-checkbox *:before {
  box-sizing: border-box;
}


.crm-checkbox input {
  position: absolute;
  opacity: 0;
}

.crm-checkbox label:hover {
  border: 1.25px solid #ACE8F6;
  box-shadow: 0 0 0 1.8px #E8FAFF;
}


.crm-checkbox input:checked + label {
  background-color: #8AD9ED;
  border: 1px solid #34B8D9;
}

.crm-checkbox input + label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border: 1.25px solid #D3E8ED;
  width: var(--size);
  height: var(--size);
  border-radius: 4px;
  cursor: pointer;
  transition: all .2s ease;
}
.crm-checkbox input:disabled + label {
  background-color: #F0F8FD;
  border: 1.25px solid #D3E8ED;
  cursor: auto;
}

.crm-checkbox input:disabled:checked + label {
  background-color: #D3E8ED;
  border: 1.25px solid #A5C5D1;
}
.crm-checkbox input:disabled + label:hover {
  box-shadow: none;
}

.crm-checkbox input + label svg {
  pointer-events: none;
  transform: rotate(-3deg);
}


.crm-checkbox input + label svg path {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 1.25px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 101;
}
.crm-checkbox input:disabled + label svg path {
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 1px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 101;
}

.crm-checkbox input:checked + label svg path {
  stroke-dashoffset: 0;
}
