.chip-hot, .chip-warm, .chip-cold, .chip-clear  {
  @apply font-semibold p-2 w-fit rounded-[4px] flex items-baseline justify-center gap-2.5 select-none cursor-pointer;
  span {
    @apply w-[8px] h-[8px] rounded-full;
  }
}

.chip-hot {
  @apply bg-red-100 text-red-800;
  span {
    @apply bg-red-400;
  }
}

.chip-warm {
  @apply bg-orange-100 text-orange-800;
  span {
    @apply bg-orange-400;
  }
}

.chip-cold {
  @apply bg-primary-100 text-primary-800;
  span {
    @apply bg-primary-400;
  }
}

.chip-clear {
  @apply text-neutral-800;
}

.chip-priority, .chip-priority-high, .chip-priority-low {
  @apply font-semibold p-2 w-fit rounded-[4px] flex items-center justify-center gap-2 select-none cursor-pointer
  bg-neutral-100 text-neutral-900;
}

.chip-priority-high {
  span svg {
    path {
      stroke: #E35E43;
    }
  }
}

.chip-priority-low {
  span svg {
    path {
      stroke: #25CC79;
    }
  }
}
