/* position */
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  padding: 8px 8px 10px 8px;
  min-width: 375px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(45, 49, 51, 0.10), 0 7px 15px 0 rgba(45, 49, 51, 0.05);
  border: 1px solid #D3E8ED;
  color: #152930;
}

.ngx-toastr {
  background-color: #FFFFFF;
  pointer-events: auto;
}

