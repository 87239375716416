:root {
  --fc-border-color: #D3E8ED;
  --fc-today-bg-color: white;
}

.fc {
  .fc-button {
    @apply text-neutral-800 bg-neutral-200 p-[8px] rounded-[6px] hover:bg-neutral-300 hover:text-neutral-800 border-none
    transition-colors font-medium #{!important};

    &:focus {
      @apply shadow-none #{!important};
    }
  }

  .fc-next-button, .fc-next-button:hover, .fc-next-button:active {
    border-left: 1px solid #D3E8ED !important;
    @apply rounded-l-none z-10 #{!important};
  }

  .fc-prev-button {
    @apply rounded-r-none #{!important};
  }

  .fc-today-button {
    @apply text-primary-800 bg-primary-100 hover:bg-primary-200 hover:text-primary-800 active:bg-primary-300
    active:text-primary-800 disabled:bg-primary-200 disabled:text-primary-500 #{!important};
  }

  .fc-toolbar-title {
    @apply text-neutral-700 text-[17px];
  }

  .fc-dayGridMonth-button {
    border-right: 1px solid #D3E8ED !important;
    @apply rounded-r-none px-[12px] z-10 #{!important};
  }

  .fc-timeGridWeek-button {
    @apply rounded-none px-[12px] #{!important};
  }

  .fc-timeGridDay-button {
    border-left: 1px solid #D3E8ED !important;
    @apply rounded-l-none px-[12px] #{!important};
  }

  .fc-button-active {
    @apply bg-neutral-400 #{!important};
  }

  .fc-col-header {
    @apply bg-neutral-100;
    .fc-col-header-cell-cushion {
      @apply text-neutral-700 font-medium;
    }
  }

  .fc-day-other {
    @apply bg-neutral-100;
  }

  .fc-daygrid-day-number {
    @apply text-neutral-900 flex items-center justify-center size-[26px] mt-1 mr-1;
  }

  .fc-daygrid-day.fc-day-today {
    @apply bg-neutral-0;

    .fc-daygrid-day-number {
      @apply bg-red-500 text-neutral-0 rounded-full;
    }
  }

  .fc-h-event {
    background-color: transparent;
    @apply border-none;
  }

  .fc-event:focus {
    box-shadow: none;

    &::after {
      display: none;
    }
  }

  .fc-daygrid-event-harness {
    @apply m-[2px] #{!important};
  }

  .fc-daygrid-more-link {
    @apply text-neutral-700;

    &:hover {
      @apply bg-neutral-100;
    }
  }

  .fc-popover {
    z-index: 100;

    .fc-popover-header {
      @apply bg-neutral-100;

      .fc-popover-title, .fc-popover-close {
        @apply text-neutral-700;
      }
    }

    .fc-popover-body {
      @apply max-h-[150px] overflow-y-auto mr-1.5;
    }
  }

  .fc-scrollgrid-shrink-cushion {
    @apply text-neutral-700;
  }

  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: #F0F8FD;
    -webkit-border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 8px;
    border-radius: 10px;
    background: #C1D9E3;
  }
}
