.crm-input, .group-input {
  @apply flex items-center relative mr-1;
  span {
    @apply absolute right-3 invisible;

    svg {
      path {
        stroke: #6F94A3;
      }
    }
  }

  input, textarea {
    @apply appearance-none w-full px-4 py-3 placeholder:text-neutral-700 text-neutral-900 bg-neutral-0 rounded-[6px]
    border-[1px] border-neutral-300 active:outline-0;
  }

  input:focus {
    outline: 0;
    border: 1px solid #34B8D9;
    box-shadow: 0 0 0 3px #E8FAFF;
  }

  textarea:focus {
    outline: 0 !important;
    border: 1px solid #34B8D9 !important;
    box-shadow: 0 0 0 3px #E8FAFF !important;
  }

  input:disabled {
    @apply bg-neutral-100 text-neutral-700;
  }
}

.group-input {
  margin-right: 0;

  input {
    @apply rounded-none rounded-r-[6px];
  }

  input:focus {
    @apply border-l-[1px] border-l-neutral-300 active:outline-0 border-r-0 border-t-0 border-b-0;
    box-shadow: none;
  }

}

.crm-input-group {
  margin-right: 0.25rem;
  border: 1px solid transparent;
  border-radius: 6px;
}

.crm-input-group:focus-within {
  border: 0.5px solid #34B8D9;
  box-shadow: 0 0 0 3px #E8FAFF;
}

.crm-input:hover > span {
  visibility: visible;
  transition: visibility 200ms ease-in-out;
}
.group-input:hover > span {
  visibility: visible;
  transition: visibility 200ms ease-in-out;
}

.crm-input-icon {
  @apply flex items-center gap-2.5 text-base h-12 px-4 text-neutral-900 bg-neutral-0 rounded-[6px]
  border-[1px] border-neutral-300;

  input {
    @apply placeholder:text-neutral-700 focus:outline-0 h-11 w-full;
  }
}

.crm-input-icon:focus-within  {
  outline: 0;
  border: 1px solid #34B8D9;
  box-shadow: 0 0 0 3px #E8FAFF;
}

ngx-otp-input {
  .ngx-otp-input-form {
    .ngx-otp-input-box {
      @apply appearance-none placeholder:text-neutral-700 text-neutral-900 text-[14px] bg-neutral-0 rounded-[8px]
      border-[1px] border-neutral-300 active:outline-0 uppercase w-[36px] h-[40px];
    }

    .ngx-otp-input-box:focus {
      outline: 0;
      border: 1px solid #34B8D9;
      box-shadow: 0 0 0 3px #E8FAFF;
    }

    .ngx-otp-input-box:focus::placeholder {
      color: transparent;
    }
  }
}

