.p-fileupload .p-fileupload-buttonbar {
  display: flex;
  padding: 8px;
  gap: 4px;
  border-bottom: 1px solid #D3E8ED;
}

.p-button.p-fileupload-choose {
  background: #34B8D9;
  color: #ffffff;

  &:hover {
    background: #049FC2;
  }

  &:active {
    background: #1084A1;
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  height: 36px;
  gap: 2px;
}

.p-button {
  background: #E4F2F7;
  color: #416978;
  border: none;
  padding: 0 12px;
  margin: 0;

  &:hover {
    background: #D3E8ED;
  }

  &:active {
    background: #C1D9E3;
  }
}

.p-button.p-button-icon-only {
  background: #E4F2F7;
  color: #416978;
  width: 24px;
  height: 24px;

  &:hover {
    background: #D3E8ED;
  }

  &:active {
    background: #C1D9E3;
  }
}

.p-fileupload .p-fileupload-content {
  max-height: 200px;
  min-height: 100px;
  overflow-y: auto;
  padding: 8px;
}

.p-fileupload-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: #F0F8FD;
  border-radius: 8px;
}

.p-fileupload .p-fileupload-content.p-fileupload-highlight {
  background: #E8FAFF;
  border-color: #ACE8F6;
}

.p-fileupload-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D3E8ED;

  &:last-child {
    border-bottom: none;
  }

  > div:first-child:has(img) {
    display: none !important;
  }

  > div:nth-last-child(3) {
    flex-grow: 1;
    text-align: left;
    width: 60%;
  }

  > div:nth-last-child(2) {
    width: 80px;
    text-align: right;
    color: #6F94A3;
  }

  > div:last-child {
    flex-shrink: 0;
    width: 20px;
    padding-left: 0;
  }
}

.p-fileupload-content .p-progressbar {
  display: none;
}

.p-message.p-message-error .p-message-icon {
  margin-top: 2px;
}
