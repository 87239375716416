.p-dynamic-dialog {
  .p-dialog-content {
    font-family: 'Poppins',sans-serif;
    border-radius: 12px;
    padding: 0;
  }
  .p-dialog-header {
    padding: 0;
  }
  .p-dialog-footer {
    padding: 0;
  }
}

// Dropdown/Multiselect options list styles in dialogs.
.p-dropdown-panel {
  .p-highlight {
    color: #152930;
    background-color: #E4F2F7;
  }

  .p-inputtext:enabled:focus {
    outline: 1px solid #34B8D9;
  }
}

.p-multiselect-panel {
  .p-inputtext:enabled:focus {
    outline: 1px solid #34B8D9;
  }

  .p-checkbox {
    .p-checkbox-box {
      outline-color: #34B8D9;

      &.p-highlight {
        border-color: #34B8D9;
        background-color: white;
      }
    }

    .p-checkbox-icon {
      color: #34B8D9;
    }
  }
}

