.btn-primary, .btn-primary-sm, .btn-secondary, .btn-secondary-sm, .btn-danger, .btn-danger-sm, .btn-danger-secondary,
.btn-danger-secondary-sm, .btn-tertiary, .btn-tertiary-sm, .btn-outline, .btn-outline-sm, .btn-ghost, .btn-ghost-sm,
.btn-link, .btn-link-sm{
  @apply text-base font-medium h-12 px-4 rounded-[6px] transition-colors duration-100 disabled:opacity-70;
}

.btn-primary, .btn-primary-sm {
  @apply text-neutral-0 bg-primary-600 hover:bg-primary-700 active:bg-primary-800 disabled:bg-primary-500
  disabled:text-neutral-100;
}

.btn-secondary, .btn-secondary-sm {
  @apply text-primary-800 bg-primary-100 enabled:hover:bg-primary-200 enabled:active:bg-primary-300 disabled:text-primary-500;
}

.btn-danger, .btn-danger-sm {
  @apply text-neutral-0 bg-red-500 hover:bg-red-600 active:bg-red-700 disabled:bg-red-200;
}

.btn-danger-secondary, .btn-danger-secondary-sm {
  @apply text-red-800 bg-red-100 hover:bg-red-200 active:bg-red-300 disabled:bg-red-0 disabled:text-red-200;
}

.btn-tertiary, .btn-tertiary-sm {
  @apply text-neutral-800 bg-neutral-200 enabled:hover:bg-neutral-300 enabled:active:bg-neutral-400 disabled:text-neutral-500;

  &:disabled {
    svg path {
      stroke: #B0BEC5;
    }
  }
}

.btn-outline, .btn-outline-sm, .btn-ghost, .btn-ghost-sm {
  @apply text-neutral-700 bg-neutral-0 border-[1px] border-neutral-300 enabled:hover:bg-neutral-100
  enabled:active:bg-neutral-200 disabled:text-neutral-400;
}

.btn-ghost, .btn-ghost-sm {
  @apply border-0;

  svg path {
    stroke: #6F94A3;
  }

  &:disabled {
    svg path {
      stroke: #B0BEC5;
    }
  }
}

.btn-link, .btn-link-sm {
  @apply text-primary-600 hover:text-primary-700 active:text-primary-800 disabled:text-neutral-400 hover:underline;
}

.btn-primary-sm, .btn-secondary-sm, .btn-danger-sm, .btn-danger-secondary-sm, .btn-tertiary-sm, .btn-outline-sm,
.btn-ghost-sm .btn-link-sm {
  @apply px-3 h-8;
}

.btn-positioning {
  @apply flex items-center justify-center gap-[4px];
}

.icon-btn {
  @apply flex items-center justify-center rounded-[6px] w-[25px] h-[25px];
}
