.featured-icon-primary-xl, .featured-icon-gray-xl, .featured-icon-error-xl, .featured-icon-warning-xl,
.featured-icon-primary-l, .featured-icon-gray-l, .featured-icon-error-l, .featured-icon-warning-l,
.featured-icon-primary-m, .featured-icon-gray-m, .featured-icon-error-m, .featured-icon-warning-m,
.featured-icon-success-xl, .featured-icon-success-l, .featured-icon-success-m {
  @apply flex items-center justify-center box-content rounded-full;
}

.featured-icon-primary-xl, .featured-icon-primary-l, .featured-icon-primary-m, .featured-icon-primary-s {
  @apply border-primary-100 bg-primary-200;
  span {
    svg {
      path {
        stroke: #1084A1;
      }
    }
  }
}

.featured-icon-success-xl, .featured-icon-success-l, .featured-icon-success-m {
  @apply border-green-100 bg-green-200;
  span {
    svg {
      path {
        stroke: #16685F;
      }
    }
  }
}

.featured-icon-gray-xl, .featured-icon-gray-l, .featured-icon-gray-m, .featured-icon-gray-s {
  @apply border-neutral-100 bg-neutral-300;
  span {
    svg {
      path {
        stroke: #416978;
      }
    }
  }
}

.featured-icon-error-xl, .featured-icon-error-l, .featured-icon-error-m, .featured-icon-error-s {
  @apply border-red-100 bg-red-200;
  span {
    svg {
      path {
        stroke: #8A2A17;
      }
    }
  }
}

.featured-icon-warning-xl, .featured-icon-warning-l, .featured-icon-warning-m, .featured-icon-warning-s {
  @apply border-orange-100 bg-orange-200;
  span {
    svg {
      path {
        stroke: #795918;
      }
    }
  }
}

.featured-icon-primary-xl, .featured-icon-success-xl, .featured-icon-gray-xl, .featured-icon-error-xl, .featured-icon-warning-xl {
  @apply w-[46px] h-[46px] border-[6px];
  span {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.featured-icon-primary-l, .featured-icon-success-l, .featured-icon-gray-l, .featured-icon-error-l, .featured-icon-warning-l {
  @apply w-[40px] h-[40px] border-[6px];
  span {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.featured-icon-primary-m, .featured-icon-success-m, .featured-icon-gray-m, .featured-icon-error-m, .featured-icon-warning-m {
  @apply w-[30px] h-[30px] border-[4px];
  span {
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
