.p-calendar {
  .p-inputtext {
    border-color: #D3E8ED;
    font-size: 12px;
    color: #152930;
    box-shadow: none;

    &::placeholder {
      color: #6F94A3;
      font-size: 12px;
    }

    &:focus {
      outline: 0;
      border: 1px solid #34B8D9;
      box-shadow: 0 0 0 3px #E8FAFF;
    }
  }

  &.p-focus > .p-inputtext {
    outline-color: #D3E8ED;
  }
}
.p-datepicker {
  font-size: 12px;
  box-shadow: none;

  *:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .p-datepicker-header .p-datepicker-title {
    .p-datepicker-month,
    .p-datepicker-year {
      font-size: 12px;
      color: #152930;
    }
  }

  table td > span.p-highlight,
  .p-yearpicker .p-yearpicker-year.p-highlight,
  .p-monthpicker .p-monthpicker-month.p-highlight,
  &:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
    color: #1084A1;
    background: #CAF2FC;
  }
}
